import Vue from 'vue'
import Vuex from 'vuex'
import modules from '../apps/store.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    messages: [
      {
        id: '79afdfe3-f0f8-4486-b5b1-479fda76608d',
        message: 'Message 1'
      },
      {
        id: '7ce8e281-2e99-4b67-ac68-75280f270753',
        message: 'Message 2'
      },
      {
        id: '01b7cfb0-09a9-4ce1-99b6-f187bcce1ab7',
        message: 'Message 2'
      },
      {
        id: 'dc00dfd9-0e59-489a-ae45-8753b6928715',
        message: 'Message 2'
      },
      {
        id: '97389a33-2610-4b17-9d38-53467af3ba7f',
        message: 'Message 2'
      },
      {
        id: '2f255bce-6399-466e-a31a-aaf9ed4c55e6',
        message: 'Message 2'
      },
      {
        id: 'b7892dca-7a4d-4504-9be9-1599786aac45',
        message: 'Message 2'
      }
    ]
  },
  mutations: {
  },
  actions: {
  },
  modules
})
