import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    artists: [],
    mixes: [
      {
        title: 'Lighting Fires with Cigarettes and Kerosene',
        id: 'd8f4447a-6b82-4bf1-8c69-68c405f57ef1',
        playing: false,
        src: 'https://music.philt3r.rocks/Agents of Anarchy - Lighting Fires with Cigarettes and Kerosene.mp3',
        pcm: 'https://music.philt3r.rocks/Agents of Anarchy - Lighting Fires with Cigarettes and Kerosene.mp3.json',
        duration: 0,
        description: `The heavier side of Techno sets fire to my soul, giving me the determination to continue on my chosen path of Integrity, Honesty & Courtesy as an Agent of Anarchy!`,
        trackList: [{ title: 'Children of Society (Original Mix)', artist: 'Mark Dekoda' },{ title: 'Dystopia (Original Mix)', artist: 'Rebuke' },{ title: 'Call of the Tribes (Original Mix)', artist: 'LAAT' },{ title: 'Ecstasy (Original Mix)', artist: 'Raito' },{ title: 'Freaks & Misfits (Victor Ruiz Remix)', artist: 'Caitlin' },{ title: 'Resistance (Original Mix)', artist: 'Mha Iri' },{ title: 'Rave Power (Original Mix)', artist: 'Mondo' },{ title: 'Restore My Soul (HI-LO Remix)', artist: 'Adam Beyer, DJ Rush' },{ title: 'Once Again (Original Mix)', artist: 'UMEK' },{ title: 'I Am Machine (Original Mix)', artist: 'Thomas Schumacher, Lilly Palmer' },{ title: 'Love the Feeling (Original Mix)', artist: 'Agent Orange DJ' },{ title: 'You Are Mine (Original Mix)', artist: 'Mha Iri' },{ title: 'Rave (Adam Beyer & Layton Giordani Remix)', artist: 'Sam Paganini' },{ title: 'Liberate Your Mind (Original Mix)', artist: 'Procombo' },{ title: 'Trust In Uncertain Times (Original Mix)', artist: 'Belocca' },{ title: 'No Identity (Original Mix)', artist: 'Celic, Paxtech, GIULIA (IT)' },{ title: 'Never Stop Raving (Original Mix)', artist: 'Jay Lumen' },{ title: 'Arise (Original Mix)', artist: 'Bruce Zalcer' },{ title: 'Cigarettes & Kerosene (T78 Extended Remix)', artist: 'Will Atkinson, Cari Golden'}],
      }, {
        title: 'Techno Time May 2022',
        id: '29e44849-5067-4e8e-bee6-e38fb1b655a3',
        playing: false,
        src: 'https://music.philt3r.rocks/Techno Time May 2022 Closing Set.wav',
        pcm: 'https://music.philt3r.rocks/Techno Time May 2022 Closing Set.wav.json',
        duration: 0,
        description: `Ever since I experienced Nikki Sig closing at My Aeon I had wanted to close Techno Time so I was so stoked when LeBruh booked me to close. That was in June 2021, that gig got COVID canceled so many 
        times it was May 2022 before I got behind the Funktion 1s downstairs at My Aeon. I had so much fun! The beers were coming at me from the crowd faster than I could drink them and there was a constant stream of 
        people wanting a selfie and the dance floor was going off. Loved it and cant wait to do it again.`,
        trackList: [{ title: 'End Culture (Original Mix)', artist: 'Atroxx' }, { title: 'The Future Is Ours (Original Mix)', artist: 'Christian Smith' }, { title: 'Deconstruct (Original Mix)', artist: 'K.A.L.I.L.' }, { title: 'New Generation (Original Mix)', artist: 'Layton Giordani' }, { title: 'Dreams (UMEK Version)', artist: 'UMEK, Quench' }, { title: 'We Will Survive (Egbert & Lilly Palmer 2020 Rework)', artist: 'Warp Brothers' }, { title: 'Resistance (Original Mix)', artist: 'Mha Iri' }, { title: 'G.O.D. (Gospel of Doubt) ft Casey Gerald (Spektre Remix)', artist: 'Pleasurekraft' }, { title: '(Hope)less (Original Mix)', artist: 'Handsdown, Leigh Boy' }, { title: 'It Might Be Reality (Original Mix)', artist: 'Suspect One' }, { title: 'Reconnection (Luis M Remix)', artist: 'Kleiman' }, { title: 'now-if-any-of-you-sons-of-bitches-got-anything-else-to-say', artist: 'O-Ren Ishii' }]
      }, {
        title: 'Digital Hotdog 2022',
        id: 'cc4e3ef1-48ff-47ae-8b5e-e488e9c88568',
        playing: false,
        src: 'https://music.philt3r.rocks/Digital Hotdog 2022.wav',
        pcm: 'https://music.philt3r.rocks/Digital Hotdog 2022.wav.json',
        duration: 0,
        description: `Digital Hotdog 2022 was an awesome old skool bush party! With about 50 doofers camped in the middle of a state forest 2 hours out of Melbourne. 
        This was the first time I had DJ'd to real people since Esoteric Festival 2020! Another DJ had gotten lost on the way so I got extra time and went rogue just playing tunes
        that made the crowd crazy. Fantastic night in the Aussie bush.`,
        trackList: [{ title: 'End Culture (Original Mix)', artist: 'Atroxx' }, { title: 'The Future Is Ours (Original Mix)', artist: 'Christian Smith' }, { title: 'Deconstruct (Original Mix)', artist: 'K.A.L.I.L.' }, { title: 'New Generation (Original Mix)', artist: 'Layton Giordani' }, { title: 'Die Antwood - I Fink U Freeky (Filth & Pleasure Remix)-Master(jd)' }, { title: 'Dreams (UMEK Version)', artist: 'UMEK, Quench' }, { title: 'We Will Survive (Egbert & Lilly Palmer 2020 Rework)', artist: 'Warp Brothers' }, { title: 'G.O.D. (Gospel of Doubt) ft Casey Gerald (Spektre Remix)', artist: 'Pleasurekraft' }, { title: 'It Might Be Reality (Original Mix)', artist: 'Suspect One' }, { title: 'Reconnection (Luis M Remix)', artist: 'Kleiman' }, { title: "now-if-any-of-you-sons-of-bitches-got-anything-else-to-say-now-is-the-fucking-time!-i-didn't-think-so" }, { title: 'Sabotage (Original Mix)', artist: 'Joyce Mercedes' }, { title: 'The Meg (Original Mix)', artist: 'Handsdown, Leigh Boy' }, { title: 'Glocken lÃ¤uten (Pavel Petrov Remix)', artist: 'Maksim Dark' }, { title: 'Dreams Are Destroyed (Gaga & Mateo! Remix)', artist: 'Maksim Dark' }, { title: 'Back Into Consciousness (Original Mix)', artist: 'Spektre' }, { title: 'Blood To The Gods (Mittens)', artist: 'Modular Phaze, TANKHAMUN' }, { title: 'Neuroptera (Original Mix)', artist: 'Mha Iri, ABYSSVM' }]
      },
      {
        title: 'Esoteric Festival 2020',
        id: 'c2e46f4c-0df1-40fb-bcb7-08906f9e961c',
        playing: false,
        src: 'https://music.philt3r.rocks/Esoteric Festival 2020 Bush Techno.wav',
        pcm: 'https://music.philt3r.rocks/Esoteric Festival 2020 Bush Techno.wav.json',
        duration: 0,
        description: `At Esoteric Festival I teamed up with one of my favourite DJs, ErrolJ, and we played B2B as Pherrol on the Bush Techno stage. After going to Esoteric in 2019 and having an insanely good time
        I was jumping out of my skin when Muska told me one night at My Aeon that Pherrol was getting an opportuntiy to bring our sound to the Bush Terchno stage. On the day we dropped a few edits and remixes we had 
        made. We played Charlie Chaplin's speech from The Great Dictator and Mad by Dennis Cruz and really got the crowd moving. Dropping Beyonce's Formation sample over ANNA's Drum Machines Do Have Soul was heaps of fun. You can watch the 
        whole set here https://www.youtube.com/watch?v=ZGJTS1Zb_Yk`,
        trackList: [{ title: 'Worn out (Original Mix)', artist: 'Bulaklak' }, { title: 'Connecting The Dots (Original Mix)', artist: 'Bulaklak' }, { title: 'Mad (Original Mix)', artist: 'Dennis Cruz' }, { title: 'Erheben (Original Mix)', artist: 'Luis M, Mr Red' }, { title: 'Mad Truck (Alberto Ruiz Remix)', artist: 'Maksim Dark' }, { title: 'Arkala (Original Mix)', artist: 'D-Deck' }, { title: 'Cool Spot (Original Mix)', artist: 'Sascha Ciccopiedi' }, { title: 'Binary 303 (Da Fresh Remix)', artist: 'Danny Fontana, Rich Venom' }, { title: 'Hidden (Monococ Remix)', artist: 'Dok & Martin' }, { title: 'Drum Machines Do Have Soul (Original Mix)', artist: 'ANNA' }, { title: 'Formation - 0.0.1_PN' }, { title: 'Insomnia (Original Mix)', artist: 'Massive Moloko' }, { title: 'Magnetic (Original Mix)', artist: 'ANNA' }, { title: 'The Great Dictator', artist: '@pherrol' }, { title: 'One More (Original Mix)', artist: 'Waitz' }, { title: 'Cilene (Original Mix)', artist: 'Oscar L, Matt Sassari' }, { title: 'Reflection (Egbert Remix)', artist: 'Elay Lazutkin' }, { title: 'Gastown (Original Mix)', artist: 'Gaga, Mateo!' }, { title: 'Moonwalking Bear Sample - 126' }]
      },
      {
        title: 'Dragon Dreaming 2017',
        id: '37a7d752-e282-4d8a-87ae-f6ad2861941e',
        playing: false,
        src: 'https://music.philt3r.rocks/Dragon Dreaming 2017.wav',
        pcm: 'https://music.philt3r.rocks/Dragon Dreaming 2017.wav.json',
        duration: 0,
        description: `My friend and co-conspirator Psychedelic Rainbow KaTgirl Superstar DJ and I DJ'd as a duo for years and playing Friday night at Dragon Dreaming had become our thing. But when KaT died the music died for me.  
        Everything sounded like coins in a washing machine and I could no longer DJ. In June that year JJ from Offtap told me I was on the main stage at Psyland and had to play psytrance! 
        Thanx to JJ I got back on the decks and had a major healing moment as I smashed out a bunch of proggy psytrance tunes in the morning sun. This set is my tribute set to KaT who was
        mad as a cut snake and I loved her for it. I had her ashes on stage with me and we rocked out one more time.`,
        trackList: [{ title: 'Your Bitch', artist: 'Flexb, Qwez' }, { title: 'Do It Again (Plastic Robots Bootleg)', artist: 'Chemical Brothers' }, { title: 'Gold, Diamonds & Money (Original Mix)', artist: 'Gustavo Mota, Flexb' }, { title: 'Moudness (Original Mix)', artist: 'Miguel Bastida' }, { title: "Dying Of The Light (Victor's Interstellar Remake)", artist: 'Victor Ruiz' }, { title: 'I Am (Original Mix)', artist: 'Noir, Cari Golden, Victor Ruiz' }, { title: 'Astral Projection (Original Mix)', artist: 'Enrico Sangiuliano' }, { title: 'Forum (Original Mix)', artist: 'Julian Jeweil' }, { title: 'Going Down (Original Mix)', artist: 'Adam Beyer, Dense & Pika' }, { title: 'In Love (Original Mix)', artist: 'Pig&Dan, Adam Beyer' }, { title: 'Fear & Loathing (Original Mix)', artist: 'Eric Sidey, Fabrication' }, { title: 'People Call Me Rude (Original Mix)', artist: 'YROR?' }, { title: 'Fck Monday (Original Mix)', artist: 'Prosdo' }, { title: 'Secret About You (Original Mix)', artist: 'Droplex, FiveAm' }, { title: 'The Runner (Original Mix)', artist: 'Christian Smith, Victor Ruiz' }, { title: 'Good For YOU (Minimal Men Remix)', artist: 'Duane Bartolo' }, { title: 'Calabria (Club Mix)', artist: 'Cat Dealers' }]
      },
      {
        title: 'Ghost the System',
        id: '929d5383-e0e5-42d0-9d09-4fcd7a486f72',
        playing: false,
        src: 'https://music.philt3r.rocks/Agents of Anarchy - Episode 1.wav',
        pcm: 'https://music.philt3r.rocks/Agents of Anarchy - Episode 1.wav.json',
        duration: 0,
        description: 'This set embodies the message I want to covey. Most of the producers are from Melbourne or Australia and all the tracks convey the anti-dystopic sound. First track is one I wrote.',
        trackList: [{ title: 'Ghost the System', artist: '@philt3r' }, { title: 'G.O.D. (Gospel of Doubt) ft Casey Gerald (Spektre Remix)', artist: 'Pleasurekraft' }, { title: 'Murderers (Original Mix)', artist: 'Mike Turing, Tom Schippers' }, { title: 'Aria (Original Mix)', artist: 'Mha Iri' }, { title: 'Machine Learning (Original Mix)', artist: 'Fruhwerk' }, { title: 'Pura Ignis (Samwise (AUS) Remix)', artist: 'WISER.' }, { title: 'now-if-any-of-you-sons-of-bitches-got-anything-else-to-say', artist: 'O-Ren Ishii' }, { title: 'Freak (Original Mix)', artist: 'Barbuto, Mittens' }, { title: 'Primordial (Original Mix)', artist: 'Pleasurekraft' }, { title: 'Back Into Consciousness (Original Mix)', artist: 'Spektre' }, { title: 'Aetheria (Barbuto Remix)', artist: 'Mha Iri, WISER.' }, { title: 'Trust the Culture (Original Mix)', artist: 'Franco Paulsen' }, { title: 'Subliminal (Original Mix)', artist: 'Danny Wabbit' }, { title: 'Perception (Handsdown & Leigh Boy remix)', artist: 'Unknown Concept' }, { title: 'Carnival of Souls (Original Mix)', artist: 'Spektre' }, { title: 'State of Mind (Bolster Remix)', artist: 'Mha Iri' }, { title: 'Black Heart (Original Mix)', artist: 'Spektre, Juliet Fox' }, { title: 'Your Heart (Original Mix)', artist: 'Mha Iri' }, { title: 'Connected (Original Mix)', artist: 'Ecko2, Soultekmind' }, { title: 'Liquid Light (Original Mix)', artist: 'Mha Iri' }, { title: 'Changing World (Original Mix)', artist: 'Filterheadz' }, { title: 'Let There Be Dark (Original Mix)', artist: 'Beico & Mt93' }, { title: 'New Age Conspiracy (KLINES Remix)', artist: 'Mittens' }, { title: 'Dark Side Theory (Original Mix)', artist: 'Mittens' }, { title: 'Blood To The Gods (Mittens)', artist: 'Modular Phaze, TANKHAMUN' }, { title: 'Neuroptera (Original Mix)', artist: 'Mha Iri, ABYSSVM' }]
      },
      {
        title: 'Pink Super Moon',
        id: '89bfe6dc-8815-494e-a5eb-4922bd75658a',
        playing: false,
        src: 'https://music.philt3r.rocks/Psyland2021.wav',
        pcm: 'https://music.philt3r.rocks/Psyland2021.wav.json',
        duration: 0,
        description: 'On Monday April 26 2021 Lucy said I should go outside and look at the Pink Supermoon. It was huge & bright in a clear black sky, tightly wrapped in a rainbow that I instantly recognised as my offtap, lunatic, amazing friend Psychedelic Rainbow KaTgirl Superstar DJ. We both wanted to talk so I got a beanbag, blanket, beer and ciggies and sat on the footpath out the front of my suburban home staring up at the moon. I couldn’t let her off for leaving me so I teased her about not being able to DJ with me anymore and point blank told her she was out of touch with the Techno scene and she was old news. That got a rise out of her! Next minute I’m inside grabbing my laptop & headphones opening a browser and heading to Beatport while heading back to my beanbag. In the space of 15 minutes I’ve bought 16 new tracks under the clear direction of KaT. Then we did our Katgirl & PHILT3R thing and smashed out an insanely mad set of techno. KaT & PHILT3R call this set “Pink Supermoon”',
        trackList: [{ title: 'End Culture (Original Mix)', artist: 'Atroxx' }, { title: 'Warpspeed (T78 Remix)', artist: 'Egbert' }, { title: 'Euphoria (Original Mix)', artist: 'Egbert, Metodi Hristov' }, { title: 'Addicted (Original Mix)', artist: 'Charles D (USA)' }, { title: 'Evolution (Original Mix)', artist: 'Cosmic Boys, UMEK' }, { title: 'Geometric (Cosmic Boys Remix)', artist: 'Mark Reeve' }, { title: 'Predator (Original Mix)', artist: 'UMEK' }, { title: 'Hypnotized (Cosmic Boys Remix)', artist: 'J Swink' }, { title: 'Stranger Selves (Cosmic Boys Remix)', artist: 'Ryan Michael Robbins' }, { title: 'My Soul (Original Mix)', artist: 'Cosmic Boys' }, { title: 'Planet X (Original Mix)', artist: 'Space 92, The YellowHeads' }, { title: 'Obsessive (Original Mix)', artist: 'Darian Jaburg, Greg Notill' }, { title: 'Driller (Original Mix)', artist: 'The YellowHeads, UMEK' }, { title: 'The Black Question (Original Mix)', artist: 'Darian Jaburg, Greg Notill' }, { title: 'Inner Circle (Extended Version)', artist: 'Ramon Tapia, The YellowHeads' }, { title: 'Burn Steadily (Original Mix)', artist: 'Mha Iri' }, { title: 'Neuroptera (Original Mix)', artist: 'Mha Iri, ABYSSVM' }]
      },
      {
        title: 'Psyland 2018',
        id: 'ded268f1-8763-466e-b06c-f2e9a70bf1f8',
        playing: false,
        src: 'https://music.philt3r.rocks/Psyland 2018 Techno.mp3',
        pcm: 'https://music.philt3r.rocks/Psyland 2018 Techno.mp3.json',
        duration: 0
      }
    ]
  },
  actions: {
    async initialise ({ state, commit, dispatch }) {
    }
  },
  mutations: {},
  modules: {}
}
