import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    imageIndex: 0,
    photos: [
      'https://media.philt3r.rocks/photos/philt3r-rain-room.jpg',
      'https://media.philt3r.rocks/photos/pherrol-esoteric-2020.png',
      'https://media.philt3r.rocks/photos/Awesome-smoky-shot-Boaz.jpg',
      'https://media.philt3r.rocks/photos/dragon-2016.jpg',
      'https://media.philt3r.rocks/photos/Digital Hotdog 2022.jpeg',
      'https://media.philt3r.rocks/photos/djing-squared-senses.jpg',
      'https://media.philt3r.rocks/photos/profile-pic.jpg',
      'https://media.philt3r.rocks/photos/Loving-it-on-stage-Boaz.jpg',
      'https://media.philt3r.rocks/photos/massive-crowd.jpg',
      'https://media.philt3r.rocks/photos/me-smile-on-my-dial-dragon.jpg',
      'https://media.philt3r.rocks/photos/philt3r_main_room.jpg',
      'https://media.philt3r.rocks/photos/psyland-2017-mixing.jpg',
      'https://media.philt3r.rocks/photos/Psyland-Main-Stage.jpg'
    ],
    posters: [
      'https://media.philt3r.rocks/posters/eat-the-beat.jpg',
      'https://media.philt3r.rocks/posters/techno-time-may-2022.jpeg',
      'https://media.philt3r.rocks/posters/Dragon-Dreaming-2017.jpg',
      'https://media.philt3r.rocks/posters/psyland2017.jpg',
      'https://media.philt3r.rocks/posters/Techno Time July 2021.jpeg',
      'https://media.philt3r.rocks/posters/Live-my-aeon.jpg',
      'https://media.philt3r.rocks/posters/Equinox - Rainbird EP Release.jpg',
      'https://media.philt3r.rocks/posters/dragon-dreaming-2016.jpg',
      'https://media.philt3r.rocks/posters/eathcore-2015-flyer.jpg',
      'https://media.philt3r.rocks/posters/philt3r-techno-time.jpeg',
      'https://media.philt3r.rocks/posters/acid-punk-techno-junk.png',
      'https://media.philt3r.rocks/posters/AWC2015.jpg',
      'https://media.philt3r.rocks/posters/AWCLineUp.png',
      'https://media.philt3r.rocks/posters/DigitalHotDog-2017.jpg',
      'https://media.philt3r.rocks/posters/Friction.jpg',
      'https://media.philt3r.rocks/posters/pherrol-esoteric-2020.jpg',
      'https://media.philt3r.rocks/posters/psyland-2016.jpg',
      'https://media.philt3r.rocks/posters/ShipReKt 2015.jpg',
      'https://media.philt3r.rocks/posters/squared-senses.jpg',
      'https://media.philt3r.rocks/posters/The Solution Time is Now.jpg'
    ],
    art: [
      'https://media.philt3r.rocks/photos/GospelOfDoubt.png',
      'https://media.philt3r.rocks/photos/Never Too Old For Techno.png',
      'https://media.philt3r.rocks/art/agents-of-anarchy.png',
      'https://media.philt3r.rocks/art/Ghost-The-System.png'
    ]
  },
  actions: {
    async initialise ({ state, commit, dispatch }) {
    }
  },
  mutations: {
    SET_IMAGE_INDEX (state, index) {
      state.imageIndex = index
    }
  },
  modules: {}
}
