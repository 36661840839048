import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '../apps/routes.js'

Vue.use(VueRouter)

const allRoutes = routes.concat([
  {
    path: '/',
    component: () => import('@/apps/tunes/layouts/edgetunes/Index.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/Home.vue')
      }
    ]
  },
  {
    path: '/bio',
    component: () => import('@/apps/tunes/layouts/edgetunes/Index.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/Bio.vue')
      }
    ]
  },
  {
    path: '/photos',
    component: () => import('@/apps/tunes/layouts/edgetunes/Index.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/Photos.vue')
      }
    ]
  },
  {
    path: '/videos',
    component: () => import('@/apps/tunes/layouts/edgetunes/Index.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/Videos.vue')
      }
    ]
  },
  {
    path: '/posters',
    component: () => import('@/apps/tunes/layouts/edgetunes/Index.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/Posters.vue')
      }
    ]
  },
  {
    path: '/comics',
    component: () => import('@/apps/tunes/layouts/edgetunes/Index.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/Comics.vue')
      }
    ]
  },
  {
    path: '/art',
    component: () => import('@/apps/tunes/layouts/edgetunes/Index.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/Art.vue')
      }
    ]
  },
  {
    path: '/chat',
    component: () => import('@/apps/tunes/layouts/edgetunes/Index.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/Chat.vue')
      }
    ]
  }
])

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes: allRoutes
})

export default router
