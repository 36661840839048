const routes = [
  {
    path: '/tunes',
    component: () => import('@/apps/tunes/layouts/edgetunes/Index.vue'),
    children: [
      {
        path: '',
        component: () => import('@/apps/tunes/views/tunes/Index.vue')
      }
    ]
  },
  {
    path: '/track/:id',
    component: () => import('@/apps/tunes/layouts/edgetunes/Index.vue'),
    children: [
      {
        path: '',
        component: () => import('@/apps/tunes/views/tunes/Track.vue')
      }
    ]
  }
]

export default routes
